var Smartfreight = Smartfreight || {};
/*
 YouTube events bound to global scope with
 custom event binding. To prevent race conditions
 and load order issues between YouTube API and module scripts
 */

(function($, jQuery) {
    'use strict';

    Smartfreight.YouTube = {
        ready: false
    };

    // Load the IFrame Player API code asynchronously.
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /**
     * https://developers.google.com/youtube/player_parameters
     */
    window.onYouTubePlayerAPIReady = function() {
        window.Smartfreight.YouTube.ready = true;
        $(document).trigger('youtube-ready');
    };

})(jQuery, jQuery);
