(function ($, window, undefined) {
    'use strict';

    function VideoPopup(options) {
        this.player;
        this.options = {
            parent: '.module-a-feature',
            videoModule: '.module-video',
            iframeVideo: 'a-feature-video',
            button: '.button',
        };

        this.options = $.extend({}, this.options, options);

        this.$parent = $(this.options.parent);

        this.$videoModule = this.parent(this.options.videoModule);
        this.$button = this.parent(this.options.button);

        if (this.$parent.length > 0 && this.$videoModule.length > 0) {
            var self = this;

            if (Smartfreight.YouTube.ready) {

                this.createPlayer();
                this.bindEvents();

            } else {

                /*@see YouTube.js*/
                $(document).bind('youtube-ready', function () {
                    self.createPlayer();
                    self.bindEvents();
                });
            }

        } else {
            return;
        }
   }

    VideoPopup.prototype.parent = function (selector) {
        return $(selector, this.$parent);
    };

    VideoPopup.prototype.createPlayer = function(){
        this.player = new YT.Player(this.options.iframeVideo);
    };

    VideoPopup.prototype.bindEvents = function () {
        var self = this;

        this.$button.on('click', function (e) {
            e.preventDefault();
        });

        this.$videoModule.on('shown.bs.modal', function (e) {
            self.player.playVideo();
        });

        this.$videoModule.on('hide.bs.modal', function (e) {
            self.player.stopVideo()
        });
    };

    window.Site = window.Site || {};
    window.Site.Components = window.Site.Components || {};
    window.Site.Components.VideoPopup = VideoPopup;
})(jQuery, window);

