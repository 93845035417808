window.Site = window.Site || {};
window.Site.Components = window.Site.Components || {};

$(document).ready(function () {
    // MOBILE MENU USES MMENU JQUERY PLUGIN
    // set up the mobile menu
    $("#mobile-menu").mmenu({
        // Options
        "offCanvas": {
            "position": "right"
        }

    });
    var API = $("#mobile-menu").data("mmenu");
    $(".burger").click(function () {
        API.open();
    });
    $(".burger").click(function () {
        API.close();
    });


    // Get the value of the request a demo email and redirect to contact page. Then prevalue the email input with the value
    $('.formtick').on('click', function () {
        console.log('form ticked pressed');
        var requestDemoEmail = $('#requestDemoEmail').val();
        console.log(requestDemoEmail);
        localStorage.setItem("email", requestDemoEmail);
        window.location.href = "/contact/";
    });

    $('#requestDemoEmail').keypress(function (e) {
        if (e.which == 13) { //Enter key pressed
            $('.formtick').click(); //Trigger search button click event
        }
    });

    $('#formModel_Email').val(localStorage.email);

    $('.country-drop').on('click', function () {
        $('.country-drop .not-shown').toggle();
    });

    // There is a bug with the font in IE11
    $('#requestDemoEmail').trigger('blur');

    setMinimumHeight('.module-feature-image-text-list', '.description');
    setMinimumHeight('.module-feature-image-text-list', '.title');

    function setMinimumHeight(moduleClass, targetItemCssClass) {
        if (window.innerWidth >= 768) // Screen size above mobile
        {
            var $modules = $(moduleClass);
            $modules.each(function () {
                var minHeight = 0;
                var $currentItem = $(this);
                var $targetItems = $currentItem.find(targetItemCssClass);

                if ($targetItems || $targetItems.length > 1) {
                    $targetItems.each(function () {
                        if ($(this).height() > minHeight) {
                            minHeight = $(this).height();
                        }
                    });

                    $targetItems.each(function () {
                        $(this).css("min-height", minHeight);
                    });
                }
            });
        }
    }

    /*
    DE New module initializers
     */


    var ProviderSlider = new Site.Components.ImgGridSlider({
        parent: '.module-transport-provider-slider',
        swiperConfig: {
            speed: 1500,
            allowTouchMove: false,
            autoplay: {
                delay: 5000
            }
        }
    })

    var VideoPopup = new Site.Components.VideoPopup({
        parent: '.module-a-feature'
    })

    function initCustomerSpotlight(){

        var CustomerSpotlight = new Site.Components.ImgGridSlider({
            parent: '.module-customer-spotlight',
            swiperConfig: {
                speed: 1500,
                slidesPerView: 2,
                spaceBetween: 30,
                allowTouchMove: false,
                autoplay: {
                    delay: 2000
                },
                breakpointsInverse: true,
                breakpoints: {
                    992: {
                        slidesPerView: 4
                    }
                }

            }
        })
    }

    initCustomerSpotlight();

	$("iframe.resize-to-content").on("load", function() {
		$(this).iFrameResize();
    });

    $(document).on("click", '[data-toggle="lightbox"]', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox({
            alwaysShowClose: true,
            onShow: function () {
                if (!this._titleIsShown) {
                    $(this._$modal).addClass("no-title");
                }
            },
            onShown: function () {
                var iframe = $(this._$modal).find("iframe");
                var src = iframe.attr("src");
                if (src.indexOf("autoplay=1") > 0) {
                    iframe.attr("allow", "autoplay").attr("src", src);
                }
            }
        });
    });

    $("a[href*='cdn.intelligencebank.com'][href*='/mp4/']").each(function () {
        var href = $(this).attr("href");
        href += (href.indexOf("?") >= 0 ? "&" : "?") + "autoplay=1";
        $(this).attr("data-toggle", "lightbox")
            .attr("data-type", "url")
            .attr("data-width", "720")
            .attr("data-height", "405")
            .attr("href", href);
    });
});



$(window).bind("load", function () {
    /* General Page load smooth scroll */
    initSmoothScroll();

    /*   Trust center smooth scroll  */
    initTrustCenterSmoothScroll();

    function initSmoothScroll() {
        var url = window.location.href;
        var hashIndex = url.indexOf('#');

        if (hashIndex == -1) {
            return false;
        }

        var sectionId = url.substring(hashIndex);
        scrollToTargetSection(sectionId);
    }

    function scrollToTargetSection(sectionId) {
        var section;
        try {
            section = $(sectionId);
        } catch (error) {
            return;
        }
        if (section.length > 0) {
            var offSet = section.position();
            var topOffSet = offSet.top;
            var scrollPos = 0;

            var $desktopHeader = document.getElementById('master-header');
            var desktopHeaderOffSet = $desktopHeader.clientHeight;

            var $mobileHeader = document.getElementById('mobile-header');
            var isMobileVersion = window.getComputedStyle($desktopHeader).display === "none";

            if (isMobileVersion) {
                $('body,html').animate({
                    scrollTop: topOffSet - $mobileHeader.clientHeight
                }, 1000);

                return;
            }

            // If scroll position is less than 600 then the sticky header does not appears else it appears
            // When sticky header appears, we need to consider it's height also.
            if (topOffSet < 600) {
                scrollPos = topOffSet;
            } else {
                scrollPos = topOffSet - desktopHeaderOffSet;
            }

            $('body,html').animate({
                scrollTop: scrollPos
            }, 1000);
        }
    }

    function initTrustCenterSmoothScroll() {
        var $subNavModule = $('.module-sub-nav');
        var $subNavModuleLink = $('.module-sub-nav a');

        if ($subNavModule) {
            $subNavModuleLink.click(function (e) {
                var targetId = $(this).attr('href');
                if (targetId != '') {
                    scrollToTargetSection(targetId);
                }

                e.preventDefault();
            });
        }
    }
});
