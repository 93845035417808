(function($, window, undefined){
    'use strict';

    function ImgGridSlider(options) {
        this.swiper = null;

        this.options = {
            parent: '',
            swiperContainer: '.swiper-container',
            swiperConfig: {}
        };

        this.options = $.extend({}, this.options, options);

        this.$parent = $(this.options.parent);
        this.$swiperContainer = this.parent(this.options.swiperContainer);

        if (this.$parent.length > 0){

            this.initSwiper();

        } else {
            return;
        }
    }


    ImgGridSlider.prototype.parent = function(selector) {
        return $(selector, this.$parent);
    };

    ImgGridSlider.prototype.initSwiper = function() {
        this.swiper = new Swiper(this.$swiperContainer, this.options.swiperConfig);
    };

    window.Site = window.Site || {};
    window.Site.Components = window.Site.Components || {};
    window.Site.Components.ImgGridSlider = ImgGridSlider;


})(jQuery, window);
